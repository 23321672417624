import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Order_By,
  useGetAllCategoryKnowledgeQuery,
  useGetSeriesHomeQuery,
} from '@/generated/graphql';
import { LazyImage } from '../shared';
import Link from 'next/link';
import { UrlLink } from '@/configs/urlLink';
import {
  BlockFillLarge,
  LeftArrowIcon1,
  LockedCategoryIcon,
  RightArrowIcon1,
} from '@/assets/icons';
import Slider, { Settings } from 'react-slick';
import { HomeSeriesCard } from './HomeSeriesCard';
import { useBlockContext } from '@/context/useBlockContext';
import InfomationModal from '../shared/InfomationModal';

const KnowledgeSeriesSection = () => {
  const { block } = useBlockContext() as IBlockContext;
  const [idClicked, setIdClicked] = useState('');
  const [categoryClicked, setCategoryClicked] = useState('');

  const { data: dataSeriesCategory } = useGetAllCategoryKnowledgeQuery({
    variables: {
      orderBy: [
        {
          series_order: Order_By.AscNullsLast,
        },
      ],
    },
  });

  const filteredCategoryNotEmpty = dataSeriesCategory?.course_category.filter(
    outerItem =>
      outerItem.series_course_categories.some(
        innerItem => innerItem.series?.series_knowledges?.length > 0,
      ),
  );

  const firstFalseSeriesCategory = filteredCategoryNotEmpty?.find(
    item => item.is_block_playlist === false || item.is_block_playlist === null,
  );

  const isCourseCategory = (
    category: typeof firstFalseSeriesCategory,
  ): category is NonNullable<typeof firstFalseSeriesCategory> => {
    return category !== null;
  };

  const [currentCategoryName, setCurrentCategoryName] = useState<string | null>(
    null,
  );

  // useEffect(() => {

  // }, [block]);

  useEffect(() => {
    if (!currentCategoryName) {
      const newCategoryName = !block?.is
        ? filteredCategoryNotEmpty?.[0]?.id
        : isCourseCategory(firstFalseSeriesCategory)
        ? firstFalseSeriesCategory.id
        : '';
      setCurrentCategoryName(newCategoryName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    block,
    currentCategoryName,
    filteredCategoryNotEmpty,
    firstFalseSeriesCategory,
  ]);

  const { data: dataSeries } = useGetSeriesHomeQuery({
    variables: {
      where: {
        series_course_categories: {
          course_category_id: { _eq: currentCategoryName },
        },
      },
      orderBy: [
        {
          order: Order_By.AscNullsLast,
        },
      ],
    },
  });

  const customSlider = useRef<Slider>(null);
  const goToPrev = () => customSlider?.current?.slickPrev();
  const goToNext = () => customSlider?.current?.slickNext();

  const listSeries = useMemo(() => {
    return dataSeries?.series.filter(item => item.series_knowledges.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategoryName, dataSeries]);

  const featureSeries = useMemo(() => {
    return listSeries?.[0];
  }, [listSeries]);

  const lengSlide = (listSeries?.length || 0) - 1;

  const settingsMobile: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    variableWidth: true,
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const isOpenCategory = useCallback(
    (is_block_video: boolean) => {
      if (block.is === false) {
        return true;
      } else {
        if (!is_block_video) {
          return true;
        } else {
          return false;
        }
      }
    },
    [block.is],
  );

  const handleClickCategory = (
    id: string,
    title: string,
    is_block_playlist: boolean,
  ) => {
    setCategoryClicked('Series - ' + title);
    setIdClicked(id);
    if (isOpenCategory(is_block_playlist)) {
      setCurrentCategoryName(id);
    } else {
      setIsOpenModal(true);
    }
  };

  const isOpenFeatureCourse = useMemo(() => {
    return isOpenCategory(featureSeries?.is_block || false);
  }, [featureSeries?.is_block, isOpenCategory]);

  const handleClickFeatureCourse = () => {
    const url = `${process.env.NEXT_PUBLIC_DOMAIN}${UrlLink.knowledge.series}/${featureSeries?.slug}`;
    if (!isOpenFeatureCourse) {
      setUrlRedirect(url);
      setIsOpenModalDetail(true);
    }
  };

  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const [urlRedirect, setUrlRedirect] = useState<string | null>('');

  return (
    <div className="bg-white">
      {isOpenModal && (
        <InfomationModal
          setShowModel={setIsOpenModal}
          setCurrentCategory={setCurrentCategoryName}
          title={categoryClicked}
          idCategory={idClicked}
        />
      )}

      {isOpenModalDetail && (
        <InfomationModal
          setShowModel={setIsOpenModalDetail}
          title={urlRedirect || ''}
        />
      )}
      <div className="container pb-6">
        <div className="bg-white">
          <div className="border-neutral-grayLight flex flex-wrap items-center justify-between p-3">
            <div className="md:pb-4">
              <h2 className="font-header py-2 text-2xl font-semibold md:py-0">
                Bài viết chia sẻ
              </h2>
            </div>
            <div className="relative w-full">
              <div className="no-scrollbar w-full overflow-x-scroll pb-2 text-center">
                <ul className="flex">
                  {filteredCategoryNotEmpty?.map((item, index) => (
                    <li className="" key={index}>
                      <button
                        onClick={() =>
                          handleClickCategory(
                            item.id,
                            item.name,
                            item.is_block_playlist || false,
                          )
                        }
                        className={`flex flex-row items-center justify-center gap-1 rounded-t-lg border-b-2  px-4 py-2 ${
                          currentCategoryName === item.id
                            ? 'border-primary-eastern text-primary-eastern'
                            : 'border-transparent'
                        }
                       ${
                         isOpenCategory(item?.is_block_playlist || false)
                           ? 'cursor-pointer'
                           : 'cursor-not-allowed'
                       }
                          `}
                      >
                        {!isOpenCategory(item?.is_block_playlist || false) && (
                          <LockedCategoryIcon />
                        )}
                        <p
                          className={`text-PC-Title-Large md:text-PC-Button-Medium w-max text-[#020509] ${
                            currentCategoryName === item.id
                              ? 'text-primary-eastern'
                              : ''
                          }
                          ${
                            isOpenCategory(item?.is_block_playlist || false)
                              ? ''
                              : ' text-[#AEB3B7]'
                          }  `}
                        >
                          {item.name}
                        </p>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {featureSeries ? (
            <div
              className={`flex bg-white py-2 ${
                isOpenFeatureCourse ? '' : 'cursor-not-allowed'
              }`}
              onClick={handleClickFeatureCourse}
            >
              <div className="hidden bg-white md:flex md:w-full md:flex-row md:px-2 lg:px-3">
                {isOpenFeatureCourse ? (
                  <div className="relative h-[236px] w-full md:h-[332px]">
                    <Link
                      href={`${UrlLink.knowledge.series}/${featureSeries?.slug}`}
                    >
                      <LazyImage
                        src={featureSeries?.thumbnail || ''}
                        alt="Thumbnail course"
                        fill
                        sizes="(max-width: 768px) 50vw, (max-width: 1024px) 30vw, 25vw"
                        className={`object-fit`}
                      />
                    </Link>
                  </div>
                ) : (
                  <div className="relative h-[236px] w-full md:h-[332px]">
                    <LazyImage
                      src={featureSeries?.thumbnail || ''}
                      alt="Thumbnail course"
                      fill
                      sizes="(max-width: 768px) 50vw, (max-width: 1024px) 30vw, 25vw"
                      className={`object-fit grayscale`}
                    />
                    <BlockFillLarge className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2" />
                  </div>
                )}

                <div className="flex w-full flex-col gap-3 py-8 md:px-4 lg:px-6 ">
                  {isOpenFeatureCourse ? (
                    <Link
                      href={`${UrlLink.knowledge.series}/${featureSeries?.slug}`}
                    >
                      <h3 className="text-PC-Display-H5 md:text-PC-Display-H3 font-header">
                        {featureSeries?.title}
                      </h3>
                    </Link>
                  ) : (
                    <h3 className="text-PC-Display-H5 md:text-PC-Display-H3 font-header opacity-50">
                      {featureSeries?.title}
                    </h3>
                  )}
                  <p
                    className={`text-PC-Title-Regular font-sans text-[14px] ${
                      isOpenFeatureCourse ? '' : 'opacity-50'
                    }`}
                  >
                    {featureSeries?.series_knowledges.length} Bài viết
                  </p>
                </div>
              </div>
              <div className="w-full bg-white md:hidden">
                <div className="relative h-[234px] w-full">
                  <LazyImage
                    src={featureSeries?.thumbnail || ''}
                    alt="Thumbnail course"
                    fill
                    sizes="25vw"
                    className="object-fit"
                  />
                </div>
                <div className="flex w-full flex-col gap-3 px-3 py-4">
                  <h3 className="font-header text-PC-Display-H5">
                    {featureSeries?.title}
                  </h3>
                  <p className="text-neutral-grayDark text-PC-Caption-Medium font-sans font-semibold">
                    {featureSeries?.series_knowledges.length} Bài viết
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="slider-container relative py-6">
          {listSeries?.length ? (
            <div>
              <div className="">
                <Slider
                  {...{
                    ...settingsMobile,
                    infinite: lengSlide < 4 ? false : true,
                  }}
                  ref={customSlider}
                >
                  {listSeries
                    ?.filter(item => item.id != featureSeries?.id)
                    ?.map(item => {
                      return (
                        <div key={item?.id} className="!w-[288px] p-2">
                          <HomeSeriesCard
                            thumbnail={item?.thumbnail}
                            title={item?.title}
                            slug={item?.slug}
                            knowledges={item?.series_knowledges?.map(
                              item => item.knowledge,
                            )}
                            isBlockSeries={item?.is_block || false}
                            onClickOpenModal={setIsOpenModalDetail}
                            setCurrentCategoryName={setUrlRedirect}
                          />
                        </div>
                      );
                    })}
                </Slider>
                <div className="hidden lg:block">
                  <div
                    className={`left-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                      Number(listSeries?.length ?? 0) > 5 ? 'block' : 'hidden'
                    }`}
                  >
                    <button onClick={goToPrev} className="cursor-pointer">
                      <LeftArrowIcon1 />
                    </button>
                  </div>
                  <div
                    className={`right-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                      Number(listSeries?.length ?? 0) > 5 ? 'block' : 'hidden'
                    }`}
                  >
                    <button onClick={goToNext}>
                      <RightArrowIcon1 className="cursor-point" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default KnowledgeSeriesSection;
