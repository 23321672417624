import { BlockFill, PlayBtnWhiteIcon } from '@/assets/icons';
import LazyImage from '@/components/shared/LazyImage';
import { UrlLink } from '@/configs/urlLink';
import { useBlockContext } from '@/context/useBlockContext';
import Link from 'next/link';
import React, { useCallback, useMemo } from 'react';

interface IKnowledge {
  content?: string;
  description?: string;
  slug?: string;
  thumbnail?: string;
  title?: string;
}

export interface ISeriesCardProps {
  thumbnail?: string | null;
  slug?: string | null;
  title?: string | null;
  knowledges?: IKnowledge[];
  isBlockSeries?: boolean;
  onClickOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentCategoryName?: React.Dispatch<React.SetStateAction<string | null>>;
}

export const HomeSeriesCard = ({
  thumbnail,
  slug,
  title,
  knowledges,
  isBlockSeries,
  onClickOpenModal,
  setCurrentCategoryName,
}: ISeriesCardProps) => {
  const { block } = useBlockContext() as IBlockContext;
  const isOpenPlaylist = useCallback(
    (is_block_video: boolean) => {
      if (block.is === false) {
        return true;
      } else {
        if (!is_block_video) {
          return true;
        } else {
          return false;
        }
      }
    },
    [block.is],
  );

  const isOpenSeriesCourse = useMemo(() => {
    return isOpenPlaylist(isBlockSeries || false);
  }, [isOpenPlaylist, isBlockSeries]);

  const handleClickItem = () => {
    if (!isOpenPlaylist(isBlockSeries || false)) {
      setCurrentCategoryName &&
        setCurrentCategoryName(
          `${process.env.NEXT_PUBLIC_DOMAIN}${UrlLink.knowledge.series}/${slug}`,
        );
      onClickOpenModal(true);
    }
  };

  return (
    <div
      className={`w-full bg-[#FFFFFF] px-2 ${
        isOpenSeriesCourse
          ? 'duration-150 hover:scale-105'
          : 'cursor-not-allowed opacity-50'
      }`}
      onClick={handleClickItem}
    >
      {isOpenSeriesCourse ? (
        <Link href={`${UrlLink.knowledge.series}/${slug}`}>
          <div className="relative h-[172px] w-full  md:h-[160px]">
            <LazyImage
              src={thumbnail}
              alt="Thumbnail course"
              fill
              sizes="(max-width: 768px) 25vw, (max-width: 1024px) 20vw, 25vw"
              className="object-fit"
            />
            <PlayBtnWhiteIcon className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
        </Link>
      ) : (
        <div className="relative h-[172px] w-full  md:h-[160px]">
          <LazyImage
            src={thumbnail}
            alt="Thumbnail course"
            fill
            sizes="(max-width: 768px) 25vw, (max-width: 1024px) 20vw, 25vw"
            className="object-fit grayscale"
          />
          <BlockFill className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        </div>
      )}

      <div className="bg-white px-2 py-3">
        <div className="h-auto py-3 text-justify">
          {isOpenSeriesCourse ? (
            <Link href={`${UrlLink.knowledge.series}/${slug}`}>
              <p className="text-h5 md:text-PC-Title-Large">{title}</p>
            </Link>
          ) : (
            <p className="text-h5 md:text-PC-Title-Large">{title}</p>
          )}
        </div>
        <div className="px-1 py-2">
          <p className="text-PC-Caption-Medium">
            {knowledges?.length} bài viết
          </p>
        </div>
      </div>
    </div>
  );
};
